<template>
  <router-view/>
</template>

<style>
body {
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--surface-ground);
}
</style>
