import axios from "axios";

const api = process.env.VUE_APP_API_URL + "/user/";

export function login(user) {
  return axios
    .post(api + "login", {
      username: user.username,
      password: user.password,
    })
    .then((response) => {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("username", response.data.user.name);
      localStorage.setItem("user_id", response.data.user.id);
      localStorage.setItem(
        "user_roles",
        JSON.stringify(response.data.user.roles)
      );
      return response.data;
    });
}

export function validate() {
  return new Promise(async (resolve, reject) => {
    const token = localStorage.getItem("token");
    if (!token) {
      resolve(false);
    }

    try {
      await axios.post(api + "validate", {
        token: token,
      });
      resolve(true);
    } catch (error) {
      console.log(error);
      resolve(false);
    }
  });
}

export function edit(data) {
  data.token = localStorage.getItem("token");
  return axios.post(api + "edit", data);
}

export function delet(id) {
  return axios.post(api + "delete", {
    id: id,
    token: localStorage.getItem("token"),
  });
}

export function register(data) {
  return axios.post(api + "register", data);
}

export function list() {
  return axios
    .post(api + "list", { token: localStorage.getItem("token") })
    .then((response) => {
      return response.data;
    });
}

export function rolesList() {
  return axios
    .post(api + "roles", {
      token: localStorage.getItem("token"),
    })
    .then((response) => {
      return response.data;
    });
}
