import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    usersList: [],
    awilableRoles: [],
  },

  mutations: {
    SET_USERS_LIST_STATE: (state, list) => {
      state.usersList = list;
    },
    SET_AWILABLE_ROLES_STATE: (state, list) => {
      state.awilableRoles = list;
    },
  },
  actions: {
    GET_USERS_LIST({ commit }) {
      return axios("http://localhost/user/list", {
        method: "POST",
      }).then((res) => {
        commit("SET_USERS_LIST_STATE", res.data);
        return res.data;
      });
    },
    GET_AWILABLE_ROLES({ commit }) {
      return axios("http://localhost/user/roles", {
        method: "POST",
      }).then((res) => {
        commit("SET_AWILABLE_ROLES_STATE", res.data);
        return res.data;
      });
    },
  },
  getters: {
    USERS_LIST(state) {
      return state.usersList;
    },
    AWILABLE_ROLES(state) {
      return state.awilableRoles;
    },
  },
});
